import * as React from "react"

interface CardProps {
  children: React.ReactElement
}

export function Card({ children }: CardProps) {
  return (
    <div className="relative inline-flex justify-center w-64 bg-white rounded-md shadow-lg">
      <div className="flex flex-col items-center justify-start flex-1 px-8 py-6 rounded-md shadow-xs">
        {children}
      </div>
    </div>
  )
}
