import React from "react"
import { Card } from "../components/Card"
import { Content } from "../components/Content"
import CTA from "../components/CTA"
import ProductGrid from "../components/ProductGrid"
import PageHeading from "../components/PageHeading"
import { Product } from "../types"
import { Link } from "gatsby"
import slugify from "slugify"

const squareDots = require("../images/blue-dots.svg")
const rectDots = require("../images/dots.svg")

type HomePageTemplateProps = {
  home: {
    title: string
    support: string
    description: string
    cta: string
    headerImage: {
      image: string
      imageAlt: string
    }
    trustUs: {
      heading: string
      firstSection: {
        heading: string
        description: string
        image: string
        link: {
          label: string
          url: string
        }
      }
      secondSection: {
        heading: string
        description: string
        image: string
        link: {
          label: string
          url: string
        }
      }
      thirdSection: {
        heading: string
        description: string
        image: string
        link: {
          label: string
          url: string
        }
      }
    }
    products: {
      heading: string
      subheading: string
      cta: string
    }
    shipping: {
      heading: string
      subheading: string
      firstCard: {
        icon: string
        heading: string
        description: string
      }
      secondCard: {
        icon: string
        heading: string
        description: string
      }
      thirdCard: {
        icon: string
        heading: string
        description: string
      }
    }
    clients: {
      logo1: string
      logo2: string
      logo3: string
      logo4: string
      logo5: string
      logo6: string
    }
    closingArgument: {
      title: string
      description: string
      cta: string
    }
  }
  products: Product[]
}

export default function HomePageTemplate({
  home,
  products: allProducts,
}: HomePageTemplateProps) {
  const {
    title,
    support,
    description,
    headerImage,
    trustUs,
    products,
    shipping,
    clients,
    closingArgument,
  } = home

  const [productLink, setProductLink] = React.useState("/productos")

  const handleProductTabChange = category => {
    if (category) {
      setProductLink(`/productos/${slugify(category, { lower: true })}`)
    } else {
      setProductLink("/productos")
    }
  }
  return (
    <>
      <section className="relative pb-8 overflow-hidden min-h-auto">
        <div className="flex flex-wrap max-w-5xl px-4 pt-24 pb-8 mx-auto md:pt-32">
          <div className="w-full mx-auto md:mx-0 md:w-1/2">
            <div className="md:pr-8">
              <p className="mt-6 text-lg font-semibold tracking-wide text-orange-500 font-display">
                {support}
              </p>
              <PageHeading>{title}</PageHeading>
              <Content
                className="mt-6 text-base text-light cms-content"
                content={description}
              />

              <div className="mt-12">
                <CTA url="/contactanos">Contacta un asesor</CTA>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end w-full md:w-1/2">
            <img
              src={headerImage.image}
              alt={headerImage.imageAlt}
              className="object-cover mx-auto mt-16 md:mx-0 md:mt-0"
              style={{ width: 384, height: 512 }}
            />
          </div>
        </div>
        <div className="bg-cutout bg-gradient-to-br from-green-300 via-green-500 to-green-700"></div>
        <div
          className="absolute left-0 right-0 top-auto h-24 bg-white width-full"
          style={{ transform: "rotate(2deg)", zIndex: -2 }}
        ></div>
      </section>

      {/* Trust us section */}
      <section className="mt-10 section-container">
        <hr className="separator" />

        <h2 className="section-heading">{trustUs.heading}</h2>

        {/* features container */}
        <div className="flex flex-col w-full max-w-4xl mx-auto mt-8">
          {/* section 1 */}
          <div className="flex flex-col self-start justify-between mt-10 md:items-center md:flex-row">
            <div className="relative flex justify-start">
              <div className="w-full h-64 md:w-64">
                <img
                  className="z-20 object-cover w-full h-64"
                  src={trustUs.firstSection.image}
                  style={{ objectPosition: "0% 50%" }}
                  alt="Imagen 1"
                />
                <img
                  className="absolute"
                  src={squareDots}
                  alt=""
                  style={{
                    top: -30,
                    left: -60,
                    zIndex: -10,
                  }}
                />
              </div>
            </div>
            <div className="flex-1 md:ml-10">
              <div className="md:w-128">
                <h3 className="mt-6 section-subheading">
                  {trustUs.firstSection.heading}
                </h3>
                <p className="mt-6 text-gray-700 md:max-w-md">
                  {trustUs.firstSection.description}
                </p>
                <div className="mt-6">
                  <Link
                    to={trustUs.firstSection.link.url}
                    className="font-medium text-orange-600 transition duration-150 ease-in-out hover:underline hover:text-orange-400 focus:text-orange-400"
                  >
                    {trustUs.firstSection.link.label}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* section 2 */}
          <div className="flex flex-col items-center self-end mt-10 md:flex-row-reverse">
            <div className="flex justify-start ">
              <div className="relative w-full h-64 md:w-64">
                <img
                  className="object-cover w-full h-64"
                  src={trustUs.secondSection.image}
                  style={{ objectPosition: "0% 50%" }}
                  alt="Imagen 2"
                />
                <img
                  className="absolute"
                  src={rectDots}
                  alt=""
                  style={{
                    bottom: -30,
                    right: -60,
                    zIndex: -10,
                  }}
                />
              </div>
            </div>
            <div className="flex-1 md:ml-10">
              <div className="mr-6 md:w-128">
                <h3 className="mt-6 section-subheading">
                  {trustUs.secondSection.heading}
                </h3>
                <p className="mt-6 text-gray-700 md:max-w-md">
                  {trustUs.secondSection.description}
                </p>
                <div className="mt-6">
                  <Link
                    to={trustUs.secondSection.link.url}
                    className="font-medium text-orange-600 transition duration-150 ease-in-out hover:underline hover:text-orange-400 focus:text-orange-400"
                  >
                    {trustUs.secondSection.link.label}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* section 3 */}
          <div className="flex flex-col self-start justify-between mt-10 md:items-center md:flex-row">
            <div className="flex justify-start">
              <div className="relative w-full h-64 md:w-64">
                <img
                  className="object-cover w-full h-64"
                  src={trustUs.thirdSection.image}
                  style={{ objectPosition: "0% 50%" }}
                  alt="Imagen 3"
                />
                <img
                  className="absolute"
                  src={squareDots}
                  alt=""
                  style={{
                    bottom: -30,
                    left: -60,
                    zIndex: -10,
                  }}
                />
              </div>
            </div>
            <div className="flex-1 md:ml-10">
              <div className="md:w-128">
                <h3 className="mt-6 section-subheading">
                  {trustUs.thirdSection.heading}
                </h3>
                <p className="mt-6 text-gray-700 md:max-w-md">
                  {trustUs.thirdSection.description}
                </p>
                <div className="mt-6">
                  <Link
                    to={trustUs.thirdSection.link.url}
                    className="font-medium text-orange-600 transition duration-150 ease-in-out hover:underline hover:text-orange-400 focus:text-orange-400"
                  >
                    {trustUs.thirdSection.link.label}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Start features container */}
        </div>
      </section>
      {/* End Trust us section */}

      {/* Product Section */}
      <section className="w-full max-w-5xl mx-auto mt-10 overflow-hidden md:mt-32">
        <div className="px-8 md:px-2">
          <hr className="separator" />
          <h2 className="text-4xl font-bold leading-tight text-green-500 md:text-5xl font-display">
            {products.heading}
          </h2>
          <p className="max-w-sm text-lg leading-6 text-gray-600">
            {products.subheading}
          </p>
        </div>
        <div className="flex items-center w-full max-w-4xl">
          <div className="w-full px-8 md:px-2">
            <ProductGrid
              limit={4}
              products={allProducts}
              onTabChange={handleProductTabChange}
            />
          </div>
        </div>

        <div className="flex justify-center mt-8">
          <CTA url={productLink}>{products.cta}</CTA>
        </div>
      </section>
      {/* End Product Section */}

      {/* shipments section */}
      <section className="relative">
        <div className="mx-auto mt-32 section-container">
          <hr className="separator" />
          <h2 className="text-4xl font-bold leading-tight text-green-500 md:text-5xl font-display">
            {shipping.heading}
          </h2>
          <p className="text-lg leading-6 text-gray-600">
            {shipping.subheading}
          </p>

          <div className="flex flex-col w-full max-w-4xl mx-auto mt-16">
            <div
              className="grid grid-cols-1 gap-12 py-5 list-none md:grid-cols-3"
              style={{ justifyItems: "center" }}
            >
              <Card>
                <>
                  <img
                    className="w-auto h-24"
                    src={shipping.firstCard.icon}
                    alt="Hombre con diadema"
                  />

                  <hr className="w-16 my-3 mb-2 border-b-4 border-orange-500" />

                  <div className="self-end mt-6">
                    <span className="font-semibold">
                      {shipping.firstCard.heading}
                    </span>
                    <div className="mt-1 text-sm text-gray-700">
                      {shipping.firstCard.description}
                    </div>
                  </div>
                </>
              </Card>
              <Card>
                <>
                  <img
                    className="w-auto h-24"
                    src={shipping.secondCard.icon}
                    alt="Hombre con diadema"
                  />
                  <hr className="w-16 my-3 mb-2 border-b-4 border-orange-500" />
                  <div className="self-end mt-6">
                    <span className="font-semibold">
                      {shipping.secondCard.heading}
                    </span>
                    <div className="mt-1 text-sm text-gray-700">
                      {shipping.secondCard.description}
                    </div>
                  </div>
                </>
              </Card>
              <Card>
                <>
                  <img
                    className="w-auto h-24"
                    src={shipping.thirdCard.icon}
                    alt="Hombre con diadema"
                  />

                  <hr className="w-16 my-3 mb-2 border-b-4 border-orange-500" />

                  <div className="self-end mt-6">
                    <span className="font-semibold">
                      {shipping.thirdCard.heading}
                    </span>
                    <div className="mt-1 text-sm text-gray-700">
                      {shipping.thirdCard.heading}
                    </div>
                  </div>
                </>
              </Card>
            </div>
          </div>
        </div>
        <div className="shipment-gradient bg-gradient-to-bl from-green-400 to-green-500">
          <div className="div-block-9"></div>
          <div className="div-block-10"></div>
          <div className="div-block-11"></div>
        </div>
      </section>
      {/* end shipments section */}

      {/* Our clients section */}
      <section className="w-full max-w-5xl px-4 mx-auto mt-10 overflow-hidden md:mt-48">
        <hr className="separator" />

        <h2 className="text-4xl font-bold leading-tight text-green-500 md:text-5xl font-display">
          Nuestros clientes
        </h2>
        <p className="max-w-md text-lg leading-6 text-gray-700">
          Empresas que ya confían en nostoros para recibir sus materias primas a
          tiempo
        </p>

        <div className="flex justify-between max-w-full mt-16 space-x-6 overflow-x-auto">
          <div className="w-32 text-gray-50" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo1}
              alt="Company logo 1"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo2}
              alt="Company logo 2"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo3}
              alt="Company logo 3"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo4}
              alt="Company logo 4"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo5}
              alt="Company logo 5"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo6}
              alt="Company logo 6"
            />
          </div>
        </div>
      </section>
      {/* End Our clients section */}

      {/* CTA section */}
      <section className="cta-section bg-gradient-to-br from-green-400 via-green-500 to-green-600">
        <div className="flex items-center justify-center px-8 md:px-0">
          <div className="relative flex flex-col">
            <hr className="separator" />

            <h2 className="max-w-xl text-4xl font-bold leading-tight text-white md:text-5xl font-display">
              {closingArgument.title}
            </h2>
            <p className="max-w-lg mt-6 text-lg leading-6 text-white">
              {closingArgument.description}
            </p>
            <div className="my-6">
              <CTA url="/contactanos">{closingArgument.cta}</CTA>
            </div>
          </div>
        </div>
        <div className="cta-cut-top"></div>
        <div className="cta-cut-bottom"></div>
        <div className="cta-cut-right"></div>
      </section>
      {/* End CTA section */}
    </>
  )
}
