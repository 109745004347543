import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeTemplate from "../templates/home-page"

type HomePageProps = {
  data: {
    homePageData: { edges: any[] }
    productData: any
  }
}
const HomePage = ({ data }: HomePageProps) => {
  const { frontmatter: home } = data.homePageData.edges[0].node
  const products = data.productData.edges.map(
    (edge: { node: { frontmatter: any } }) => edge.node.frontmatter
  )

  return (
    <Layout>
      <SEO title={home.seo.title} description={home.seo.description} />
      <HomeTemplate home={home} products={products} />
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePageQuery {
    productData: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "product" }, available: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            category
            name
            provenance
            image
            packages
          }
        }
      }
    }
    homePageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            support
            description
            cta
            headerImage {
              image
              imageAlt
            }
            trustUs {
              heading
              firstSection {
                heading
                description
                image
                link {
                  label
                  url
                }
              }
              secondSection {
                heading
                description
                image
                link {
                  label
                  url
                }
              }
              thirdSection {
                heading
                description
                image
                link {
                  label
                  url
                }
              }
            }
            products {
              heading
              subheading
              cta
            }
            shipping {
              heading
              subheading
              firstCard {
                icon
                heading
                description
              }
              secondCard {
                icon
                heading
                description
              }
              thirdCard {
                icon
                heading
                description
              }
            }
            clients {
              logo1
              logo2
              logo3
              logo4
              logo5
              logo6
            }
            closingArgument {
              title
              description
              cta
            }
            seo {
              browserTitle
              title
              description
            }
          }
        }
      }
    }
  }
`
